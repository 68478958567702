@import "src/assets/styles/breakpoints";
.header-fd {
  background: url("../../assets/img/forDoctors/fd1.jpg") no-repeat center top/cover;
  padding: 100px 0;
  position: relative;
  .header-fd__head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.7);
    padding: 10px 0 7px;
    img {
      width: 180px;
    }
  }
  .header-fd__logo {
    max-width: 600px;
  }
  h1 {
    font-size: 48px;
    color: #ebebeb;
    margin-bottom: 40px;
    @include screen_xs_max {
      font-size: 30px;
    }
  }
  .header-fd__btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    button {
      margin-bottom: 20px;
    }
  }
}
.how-to-order {
  .how-to-order__items {
    max-width: 750px;
    margin: 30px auto 0;
    .how-to-order__item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      svg {
        font-size: 50px;
        color: var(--secondary);
      }
      p {
        margin-left: 100px;
      }
    }
  }
}
.indications-for-use {
  background: linear-gradient(to right, #e2e2e2, #fff);
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    @include screen_rwd_max(820) {
      grid-template-columns: 1fr;
    }
    .indications-for-use__texts {
      p {
        font-size: 16px;
        margin: 30px 0;
      }
    }
    .indications-for-use__pic {
      max-width: 450px;
      margin: 0 auto;
    }
  }
}
.how-made {
  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
      margin-top: 20px;
    }
  }
}
.threeD {
  &__pics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
  &__link {
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex;
    &:hover {
      .threeD__overlay {
        opacity: 1;
      }
    }
  }
  &__pic {
    width: 100%;
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background: rgba(255, 255, 255, 0.5);
    transition: 0.3s;
  }
}
.clinical-protocols-section {
  &__subtitle {
    margin-bottom: 20px;
  }
}
.initial-data-requirements {
  background: linear-gradient(to right, #d2e8ea, #fff);
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include screen_rwd_max(770) {
      flex-direction: column;
    }
  }
  &__pic,
  &__texts {
    width: 100%;
  }
  &__texts {
    .section-title_dark {
      @include screen_rwd_max(770) {
        text-align: center;
      }
    }
    p {
      margin: 30px 0;
    }
  }
}
.project {
  &__block-images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin: 50px 0;
    @include screen_rwd_max(530) {
      flex-direction: column;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__presentation {
    padding: 40px 0;
    position: relative;

    @include screen_rwd_max(770) {
      display: flex;
      flex-direction: column;
    }
  }

  &__presentation-btn {
    position: absolute !important;
    top: 46px;
    right: 0;

    @include screen_rwd_max(550) {
      position: relative !important;
      top: auto;
      right: auto;
      align-self: center;
      margin-top: 20px;
    }
  }

  &__register {
    flex-direction: column;
    a {
      margin-top: 40px;
      width: 100%;
      font-size: 20px;
    }
  }
}
