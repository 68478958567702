.clinical-protocols {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  .clinical-protocols__card {
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    .clinical-protocols__img {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .clinical-protocols__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.6);
        opacity: 0;
        transition: 0.3s;
        z-index: 1;
      }
      img {
        object-fit: cover;
        transition: 0.3s;
      }
      button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s;
        opacity: 0;
        z-index: 10;
      }
      &:hover {
        .clinical-protocols__overlay,
        button {
          opacity: 1;
        }
        img {
          transform: scale(1.1);
        }
      }
    }
    .clinical-protocols__title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      text-transform: uppercase;
      text-align: center;
      padding: 20px 5px;
      flex-grow: 1;
    }
  }
}
