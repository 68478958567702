@import "src/assets/styles/breakpoints";

.contactsDialog {
  &__box {
    padding: 20px;
  }
  button {
    position: absolute;
    top: 5px;
    right: 5px;
    span {
      color: #030303 !important;
    }
  }
  h2 {
    margin-bottom: 20px;
  }
  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    @include screen_xs_max {
      padding-top: 30px;
    }
    img {
      max-width: 180px;
    }
  }
  &__agent {
    margin-bottom: 20px;
    h4,
    a {
      display: block;
    }
    a {
      color: #030303;
      display: inline;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
