.classificationOfAnomaliesDialog {
  .MuiDialog-paperFullScreen {
    background: linear-gradient(#fff, #b9b9b9 30%, #fff);
  }
  &__logo {
    max-width: 250px;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }
  &__close {
    display: inline-block;
    margin: 10px 10px 10px auto;
  }
  section {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 35px 15px;
    .item {
      h2 {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
  }
  .mySlider {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 1500px) {
      max-width: 600px;
    }
    .pics {
      position: relative;
      min-height: 550px;
      @media screen and (max-width: 1500px) {
        min-height: 350px;
      }
      @media screen and (max-width: 550px) {
        min-height: 300px;
      }
      @media screen and (max-width: 430px) {
        min-height: 250px;
      }
      .progress {
        position: absolute;
        top: 26%;
        left: 36%;
        width: 130px !important;
        height: 130px !important;
      }
      img {
        width: 100%;
        position: absolute;
        z-index: 1;
        opacity: 0;
        &.loading {
          opacity: 0 !important;
        }
        &.active {
          z-index: 10;
          opacity: 1;
        }
      }
    }
    .action {
      position: relative;
      z-index: 20;
    }
  }
  .dataWrap {
    p {
      text-align: left !important;
      margin: 5px 0;
    }
    .data {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .pic {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:not(:last-child) {
          margin-right: 10px;
        }
        img {
          max-width: 100%;
        }
        p {
          text-align: center !important;
          max-width: 140px;
          margin: 0 auto;
        }
      }
    }
  }
}
