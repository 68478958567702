.sliderWithArrows {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .sliderWithArrows__nav {
    cursor: pointer;
    svg {
      font-size: 4rem;
      @media screen and (max-width: 450px) {
        font-size: 2rem;
      }
    }
  }
  .sliderWithArrows__slides {
    display: block;
    &.invisible {
      display: none;
    }
    .sliderWithArrows__slide {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}
