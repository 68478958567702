.classification {
  padding: 30px 0;
  &__container {
    max-width: 1140px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
  }
  &__logo {
    display: block;
    max-width: 200px;
  }
  &__back {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    color: #606060;
    cursor: pointer;
    margin-top: 10px;
    .icon {
      background: linear-gradient(180deg, #ababab, #cecece 100%);
      width: 34px;
      height: 34px;
      border: 1px solid #929292;
      border-radius: 50%;
      margin-right: 10px;
      transition: 0.5s;
    }
    &:hover {
      .icon {
        transform: translateX(5px);
      }
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  ul {
    list-style-position: inside;
    margin-bottom: 20px;
  }
  &__subtitle {
    margin-bottom: 10px;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 20px;
  }
  &__item {
    position: relative;
    transition: 0.5s;
    overflow: visible !important;
    max-height: max-content !important;
    &:hover {
      z-index: 30;
      .classification__main {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      }
      .classification__pic {
        img {
          transform: scale(1.05);
        }
      }
      .classification__details {
        height: auto;
        overflow: visible;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
  }
  &__pic {
    overflow: hidden;
    img {
      transition: 0.3s;
      max-width: 100%;
    }
  }
  h6 {
    font-size: 16px;
    padding: 15px 5px;
    text-align: center;
    line-height: 1.3;
  }
  &__details {
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
    position: absolute;
    z-index: 10;
    background: #fff;
    left: 0;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    p {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }

  &__data {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}
