@import "src/assets/styles/breakpoints";

.homeLayout {
  .header {
    background: url("../../assets/img/home/header-bg.jpg") no-repeat fixed center top/cover;
    padding-top: 40px;
    padding-bottom: 150px;
    .header__logoBox {
      background-color: rgba(70, 68, 68, 0.13);
      padding: 20px;
      display: flex;
      align-items: center;
      @include screen_sm_max {
        flex-direction: column;
      }
      h2 {
        font-family: Tahoma, sans-serif;
        font-size: 49px;
        font-weight: 700;
        margin-left: 35px;
        color: #656162;
      }
      .header__btns {
        margin-left: auto;
        display: flex;
        align-items: center;
        @include screen_sm_max {
          margin-left: 0;
          margin-top: 20px;
        }
        .header__doc {
          display: block;
          margin-right: 50px;
          color: #d6700d;
          text-shadow: 0 0 10px #ff942c;
          border: 1px solid #ff942c;
          box-shadow: 0 0 5px #fff;
          border-radius: 5px;
          padding: 5px 15px;
        }
        .header__enter {
          color: #030303;
          font-size: 18px;
        }
      }
    }
    h1 {
      font-size: 36px;
      font-weight: 300;
      color: #656162;
      max-width: 620px;
      margin: 70px 0 40px;
      @include screen_xs_max {
        text-align: center;
      }
    }
    .header__pics {
      display: flex;
      flex-wrap: wrap;
      gap: 30px 90px;
      @include screen_sm_max {
        justify-content: center;
        gap: 30px;
      }
      img {
        object-fit: contain;
      }
    }
  }

  .advs {
    padding: 80px 0 50px;
    box-shadow: 0 -100px 100px 70px #fff;
    z-index: 10;
    position: relative;
    .advs__box {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 20px;
      @include screen_rwd_max(653) {
        gap: 60px 20px;
      }
    }
    .advs__item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .advs__title {
        position: absolute;
        font-size: 28px;
        font-weight: 700;
        top: -28px;
        @media screen and (max-width: 653px) {
          left: 50% !important;
          top: -35px;
          transform: translateX(-50%);
        }
        &_1 {
          left: 60px;
          color: #d35400;
        }
        &_2 {
          left: 0;
          color: #e87e04;
        }
        &_3 {
          left: 80px;
          color: #e86c1a;
        }
      }
      .advs__text {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  .o {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    @include screen_rwd_max(730) {
      grid-template-columns: 1fr;
    }
    .o__text {
      padding-right: 50px;
      @include screen_rwd_max(730) {
        padding: 15px;
      }
    }
  }
  .vi {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    background: #ebebeb;
    @include screen_rwd_max(730) {
      grid-template-columns: 1fr;
    }
    .vi__text {
      padding-left: 50px;
      @include screen_rwd_max(730) {
        padding: 15px;
      }
    }
  }
  .align {
    .align__pics {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
  }
  .video {
    display: flex;
    justify-content: center;
    align-items: center;
    video {
      object-fit: cover;
    }
  }
  .agents {
    &__agent {
      max-width: 600px;
      margin-bottom: 15px;
      h4 {
        display: inline;
      }
      a {
        display: inline;
        margin-left: 5px;
        color: #030303;
        @include screen_sm_max {
          display: block;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .how-it-works {
    .how-it-works__items {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      .how-it-works__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
        }
      }
    }
  }

  .video-poster {
    max-width: 800px;
    padding: 0 15px;
    cursor: pointer;
    margin: 0 auto;
    display: flex;

    img {
      border-radius: 8px;
    }
  }
}
