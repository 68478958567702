@import "src/assets/styles/breakpoints";

.footer {
  background: #444;
  padding: 30px 0;
  margin-top: auto;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include screen_xs_max {
      flex-direction: column;
    }
  }
  .footer__logo {
    font-size: 30px;
    color: var(--primary);
  }
  .footer__nav {
    flex-grow: 1;
    display: flex;
    justify-content: space-around;
  }
}
