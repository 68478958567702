@import "src/assets/styles/breakpoints";

.clinical-protocols-dialog {
  .MuiPaper-root {
    background: transparent;
    box-shadow: none;
  }

  .MuiButton-root {
    min-width: 30px !important;
    padding: 5px !important;
  }

  &__contentBox {
    position: relative;
    overflow-y: hidden;
  }
  &__close {
    position: fixed !important;
    top: 5px;
    right: 5px;
    border-color: #fff;
    svg {
      color: #fff;
    }
  }

  &__close-text {
    @include screen_xs_max {
      display: none;
    }
  }

  &__title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    border-radius: 20px;
    color: #fff;
    background: var(--primary);
    text-align: center;
    padding: 30px 10px;
    font-weight: 700;
    font-size: 24px;
    z-index: 10;
    @include screen_xs_max {
      position: relative;
      font-size: 18px;
      padding: 5px;
      width: 88%;
      margin-bottom: 5px;
      top: auto;
      left: auto;
      transform: translateX(0);
    }
  }
  &__content {
    padding: 60px 15px 0;
    margin-top: 50px;
    max-height: calc(100vh - 110px);
    overflow-y: scroll;
    border-radius: 4px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    background: #fff;

    @include screen_xs_max {
      margin-top: 0;
      max-height: calc(100vh - 70px);
      padding-top: 10px;
      font-size: 15px;
    }

    h2,
    p {
      margin-bottom: 10px;
    }
    img {
      display: block;
      margin: 0 auto 10px;
      max-width: 80%;
    }
    h2 {
      text-align: center;
    }
    p {
      text-align: justify;
    }
  }
}
