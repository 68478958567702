.source-requirements-dialog {
  &__box {
    padding: 20px;
    position: relative;
    &__close {
      position: fixed !important;
      top: 5px;
      right: 5px;
      border-color: #fff;
      svg {
        color: #fff;
      }
    }
    h2,
    p {
      margin-bottom: 10px;
    }
    h2 {
      text-align: center;
    }
  }
}
